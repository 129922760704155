import cursor_disco from './cursor_disco.gif';
import varis_lukee from './varis.png';
import React from 'react';

let webring = require('./webring.json');
let projects = require('./projects.json');

const Home = () => {
    return (
      <div className="content">
        <p>Tervetuloa! Welcome!</p>
        <p>This variably bilingual site is my attempt to reinterpret a crumb of the web 1.0 spirit of the past with modern tools. Before the rise of social media giants, the web presence of your ordinary iternet dweller was much more varied. Creating your own HTML website for free was quite easy, and there were lots of them, each an unique image of their webmaster.</p>
        <p>Here, I hope to display some of my projects and other things that interest me, and host my attempts in creating browser games, like the <a href="../bird">Bird Parent Simulator</a>. The links section will mostly contain similarly personal sites by my friends and acquaintances.</p>
      </div>
    )
  }

  const About = () => {

    // to calculate the age of the cat... 
    function dateDiff(dateold, datenew)
    {
      var ynew = datenew.getFullYear();
      var mnew = datenew.getMonth();
      var dnew = datenew.getDate();
      var yold = dateold.getFullYear();
      var mold = dateold.getMonth();
      var dold = dateold.getDate();
      var diff = ynew - yold;
      if(mold > mnew) diff--;
      else
      {
        if(mold === mnew)
        {
          if(dold > dnew) diff--;
        }
      }
      return diff;
    }

    var toffo = new Date("02/20/2002");
    var today = new Date();
  
    var cat_age = dateDiff(toffo, today);

    return (
      <div className={"content rows"}>
        <div><img className="left" src={varis_lukee} style={{maxWidth: '200px', margin: '5px' }} alt="A hooded crow reading a book"/></div>
        <div className="half">
          <h2>About Tuisku</h2>
          <p>I study information technology at the University of Turku, and work part-time as a fullstack developer. I'm interested in small, cute robots and automation of stuff.</p>
          <p>In my free time I take care of my house plants and visit the pond nearby to see if I can spot a blue heron or some other birds. I sing in a student choir and play some instruments.</p>
          <p>I love birds, especially corvids. Hooded crows, jackdaws and magpies are my favourite birds.</p>
        </div>
      </div>
    )
  }
  
  const CursorDisco = () => {
    return (
      <div className="content nogrow">
        <img src={cursor_disco} alt="A disco ball and some cursors moving beneath it."/>
      </div>
    )
  }
  
  const Links = () => {
    return (
      <div className="content">
        <table>
          <tbody>
            {webring.map(site => (
              <tr key={site.key + '-tr'}>
                <td><a href={site.url}>{site.title}</a></td>
                <td> - </td>
                <td>{site.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
  
  const Projects = () => {
    const toggleDescription = (index) => {
      if (viewedCardIndex === index) {
        setViewedCardIndex(null);
        return;
      }
      setViewedCardIndex(index);
    };

    const [viewedCardIndex, setViewedCardIndex] = React.useState(null);
    return (
      <div className="content text-wrap text-break">
      {projects.map((project, index) => (
      <>
      {(true || viewedCardIndex === null || viewedCardIndex === index) &&
        <div className="card mb-3 shadow-sm border-0 rounded" key={index}>
          <div className="card-horizontal" style={{transition: "all 0.5s ease-in-out"}}>
            {!project.img && <div style={{ backgroundColor: 'grey', height: '100px', width: '100%' }}></div>}
            {project.img && <img src={`./img/${project.img}`} className="card-img-left rounded-top" alt={project.title} style={{transition: "all 0.5s ease-in-out", maxHeight: viewedCardIndex === index ? '200px' : '100px', width: '100%', objectFit: 'cover', objectPosition: 'left' }} />}
            <div className="card-body">
            <h5 className="card-title">{project.title}</h5>
            <p className="card-text">{project.summary}</p>
            {viewedCardIndex === index && project.description.split('\n').map((line, i) => (
              <p key={i} className="card-text">{line}</p>
            ))}
            <button onClick={() => toggleDescription(index)}>
            {viewedCardIndex === index ? 'See Less' : 'See More'}
            </button>
            </div>
          </div>
        </div>
        }
      </>
      ))}
      </div>
    )
  }

  const Cat = () => {
    var catID = Math.floor(Math.random()*1000);
    var catURL = 'https://d2ph5fj80uercy.cloudfront.net/06/cat' + catID + '.jpg';
    return (
      <div className="content nogrow">
        <p>Päivän Kissa</p>
        <img src={catURL} alt="A cat generated by an AI."/>
        <p>Kissa haettu sivulta <a href="http://thesecatsdonotexist.com/">http://thesecatsdonotexist.com/</a></p>
      </div>
    )
  }


export {Home, CursorDisco, Cat, Links, About, Projects};